<template>
  <div class="pa-3">
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              {{
                editedItem.id > 0
                  ? "Production Data # " +
                    (editedItem.info_date ? dateam(editedItem.info_date) : "")
                  : "New Production Data"
              }}</span
            >

            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <datepicker
                    label="Production Date"
                    :edit="!readonly"
                    v-model="editedItem.info_date"
                    :rules="[(v) => !!v || 'Production Date required']"
                    :date_max="today"
                    :date_min="readonly ? null : date_min"
                    :clearable="false"
                    @change="changes++"
                    :key="cs"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.surface_pressure"
                    :label="'Surface Pressure(psi)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.bottomhole_pressure"
                    :label="'Bottomhole Pressure (psi)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.oilflow_rate"
                    :label="'Oil flow Rate (bpd)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.gasflow_rate"
                    :label="'Gas Flow Rate (cf/d)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.waterflow_rate"
                    :label="'Water Flow Rate (bwpd)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.surface_temp"
                    :label="'Surface Temp (F°)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.bottomhole_temp"
                    :label="'Bottom Hole Temp (F°)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.water_cut"
                    :label="'Water Cut (%)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.gas_oil_ratio"
                    :label="'Gas Oil Ratio (cf/bbl)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.formation_pressure"
                    :label="'Formation Pressure (psi)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    dense
                    v-model.number="editedItem.pressure_gradient"
                    :label="'Pressure Gradient (psi/ft)'"
                    hide-spin-buttons
                    :readonly="readonly"
                    @input="changes++"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    v-model="editedItem.comment"
                    label="Comment"
                    @input="changes++"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="progress"
              color="primary"
              @click="save"
              :disabled="changes == 0"
              v-if="!readonly"
            >
              Save
            </v-btn>
            <v-btn color="primary" @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_PRODUCTION from "../graphql/Well/CREATE_PRODUCTION.gql";
import UPDATE_PRODUCTION from "../graphql/Well/UPDATE_PRODUCTION.gql";
import { dateam } from "@/utils/functions.js";
export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    showForm: Boolean,
    item: Object,
    list: Array,
    well: Object,
    readonly: Boolean,
  },
  data() {
    return {
      alert: false,
      type_alert: "info",
      message: "",
      frml: 600,
      progress: false,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      changes: 0,
      cs: 100,
      editedItem: {},
      corrosion: {},
    };
  },
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
      } else {
        this.editedItem = Object.assign({}, { id: -1 });
      }
    }
  },
  created() {},
  computed: {
    date_min() {
      let d = this.well.drillingdate;
      if (this.list)
        if (this.list.length > 1) {
          const todayDate = new Date(this.list[1].info_date);
          todayDate.setDate(todayDate.getDate() + 1);
          d = todayDate.toISOString().slice(0, 10);
        }
      return d;
    },
    dateam() {
      return dateam;
    },
    today() {
      return this.$store.state.today;
    },
  },
  watch: {},
  methods: {
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.progress = true;

        if (this.editedItem.id >= 0) {
          let v = {
            wellsproduction: {
              id: this.editedItem.id,
              info_date: this.editedItem.info_date,
              surface_pressure: this.editedItem.surface_pressure,
              bottomhole_pressure: this.editedItem.bottomhole_pressure,
              oilflow_rate: this.editedItem.oilflow_rate,
              gasflow_rate: this.editedItem.gasflow_rate,
              waterflow_rate: this.editedItem.waterflow_rate,
              surface_temp: this.editedItem.surface_temp,
              bottomhole_temp: this.editedItem.bottomhole_temp,
              water_cut: this.editedItem.water_cut,
              gas_oil_ratio: this.editedItem.gas_oil_ratio,
              formation_pressure: this.editedItem.formation_pressure,
              pressure_gradient: this.editedItem.pressure_gradient,
              comment: this.editedItem.comment,
            },
          };

          let r = await this.$maj(UPDATE_PRODUCTION, v);
          if (r.ok) {
            this.list.splice(this.editedItem.index, 1, this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        } else {
          let v = {
            wellsproduction: {
              well_id: this.well.id,
              info_date: this.editedItem.info_date,
              surface_pressure: this.editedItem.surface_pressure,
              bottomhole_pressure: this.editedItem.bottomhole_pressure,
              oilflow_rate: this.editedItem.oilflow_rate,
              gasflow_rate: this.editedItem.gasflow_rate,
              waterflow_rate: this.editedItem.waterflow_rate,
              surface_temp: this.editedItem.surface_temp,
              bottomhole_temp: this.editedItem.bottomhole_temp,
              water_cut: this.editedItem.water_cut,
              gas_oil_ratio: this.editedItem.gas_oil_ratio,
              formation_pressure: this.editedItem.formation_pressure,
              pressure_gradient: this.editedItem.pressure_gradient,
              comment: this.editedItem.comment,
            },
          };

          let r = await this.$maj(CREATE_PRODUCTION, v);
          if (r.ok) {
            this.editedItem.id = r.data.createWellsProduction.id;

            this.list.unshift(this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
        this.close();
        this.progress = false;
      }
    },
    close() {
      this.$emit("close");
    },

    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
